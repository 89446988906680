
<template>
  <Layout>
    <PageHeader :title="title" :breadcrumbs="breadcrumbs" />
    <div class="row">
      <div class="col">
        <div class="h-100">
          <div class="row mb-3 pb-1">
            <div class="col-12">
              <div class="d-flex align-items-lg-center flex-lg-row flex-column">
                  <div class="mt-3 mt-lg-0">
                  <form action="javascript:void(0);">
                    <div class="row g-3 mb-0 align-items-center">
                      <!--end col-->
                      <div class="col-auto">
                        <button type="button" class="btn btn-soft-danger">
                          <i class="ri-add-circle-line align-middle me-1"></i>
                          Modify Shipping Info
                        </button>
                      </div>
                    
                      <!--end col-->
                    </div>
                    <!--end row-->
                  </form>
                </div>
              </div>
              <!-- end card header -->
            </div>
            <!--end col-->
          </div>
          <!--end row-->

          <div class="row">
            <div class="col-xl-4 col-md-6">
              <!-- card -->
              <div class="card card-animate">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1 overflow-hidden">
                      <p class="text-uppercase fw-medium text-muted text-truncate mb-0">
                        Active Shipment 
                      </p>
                    </div>
                  </div>
                  <div class="d-flex align-items-end justify-content-between mt-4">
                    <div>
                      <h4 class="fs-22 fw-semibold ff-secondary mb-4">
                        <count-to :startVal='0' :endVal=activeShipping.shipping :duration='1'></count-to>
                      </h4>
                    </div>
                    <div class="avatar-sm flex-shrink-0">
                      <span class="avatar-title bg-soft-info rounded fs-3">
                        <i class="bx bx-shopping-bag text-info"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <!-- end card body -->
              </div>
              <!-- end card -->
            </div>
            <!-- end col -->

            <div class="col-xl-4 col-md-6">
              <!-- card -->
              <div class="card card-animate">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1 overflow-hidden">
                      <p class="text-uppercase fw-medium text-muted text-truncate mb-0">
                        DeadLine
                      </p>
                    </div>
                  </div>
                  <div class="d-flex align-items-end justify-content-between mt-4">
                    <div>
                      <h4 class="fs-22 fw-semibold ff-secondary mb-4">
                        {{activeShipping.deadline}}
                      </h4>
                    </div>
                    <div class="avatar-sm flex-shrink-0">
                      <span class="avatar-title bg-soft-warning rounded fs-3">
                        <i class="bx bx-user-circle text-warning"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <!-- end card body -->
              </div>
              <!-- end card -->
            </div>
            <!-- end col -->

            <div class="col-xl-4 col-md-6">
              <!-- card -->
              <div class="card card-animate">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1 overflow-hidden">
                      <p class="text-uppercase fw-medium text-muted text-truncate mb-0">
                        Next Shipment Date
                      </p>
                    </div>
                  </div>
                  <div class="d-flex align-items-end justify-content-between mt-4">
                    <div>
                      <h4 class="fs-22 fw-semibold ff-secondary mb-4">
                         {{activeShipping.next_shipping}}
                      </h4>
                    </div>
                    <div class="avatar-sm flex-shrink-0">
                      <span class="avatar-title bg-soft-primary rounded fs-3">
                        <i class="bx bx-wallet text-primary"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <!-- end card body -->
              </div>
              <!-- end card -->
            </div>
            <!-- end col -->
          </div>
          <!-- end row-->
          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-body">
                  <Tokenomics
                    title="Shippings"
                    :tokens="shipping"
                    :addToken="addShipping"
                    :onSelect="onShippingSelect"
                    :update="updateData"
                    :remove="removeData"
                    @tokenCreated="tokenCreated"
                    @tokenUpdated="tokenUpdated"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- end row-->
        </div>
        <!-- end .h-100-->
      </div>
      <!-- end col -->
    </div>
  </Layout>
</template>

<script>
  import SwiperCore from 'swiper';
  import axios from 'axios';
  import 'swiper/swiper-bundle.css';
  import Tokenomics from '@/components/tokenomics/index'
  
  SwiperCore.use([]);
  import {
    CountTo
  } from 'vue3-count-to';

  import Layout from '@/layouts/main.vue';
  import PageHeader from '@/components/page-header';

  import store from '@/state/store'


  export default {
    components: {
      CountTo,
      Layout,
      PageHeader,
      Tokenomics
    },
    created: async function() {
      // Gather configuration
      try {
        store.dispatch('config/loadContracts')
        const shippings = store.getters['config/settings'].shippings || [];
        const active = store.getters['config/settings'].active || {};
        await store.commit('shipping/initShipment', { shippings, active });
      } catch(err) {
        console.log(err)
      }
    },
    data() {
      return {
        title: 'Colton Membership Dashboard',
        breadcrumbs: []
      };
    },
    computed: {
      currentUser() {
        return store.getters['auth/currentUser']
      },
      membership() {
        const contractInfo = store.getters['config/contracts'].coltonMembership
        if (!contractInfo) {
          return {}
        } else {
          return contractInfo
        }
      },
      shipping() {
        return this.$store.state.shipping.shippings ;
      },
   
      activeShipping() {
       return this.$store.state.shipping.active ;
      },
      membershipTypes() {
       return store.getters['config/settings'].deliveries.preview.shipments
      }
    },
    methods: {
      rightcolumn() {
        if (document.querySelector('.layout-rightside-col').classList.contains('d-none')) {
          document.querySelector('.layout-rightside-col').classList.remove('d-none')
        } else {
          document.querySelector('.layout-rightside-col').classList.add('d-none')
        }
      },
         onShippingSelect(id) {
        store.commit('shipping/setVisible', id);
       },
      addShipping() {
        store.commit('shipping/addShipping', {
          deadline: new Date().toISOString().slice(0, 10),
          active: false,
          next_shipping: new Date().toISOString().slice(0, 10),
        })
         
      },
      updateData() {
        var data = {
          active: this.activeShipping,
          shippings: this.shipping,
        }
        axios.post(process.env.VUE_APP_API_URL + 'settings/shipments/update' , data).then((data) => {
          console.log(data);
        }).catch((e) => {
          console.log(e)
        })
           },
      
      removeData(id) {

        store.commit('shipping/removeShipping', id);
         console.log(id)
      }
    }

  };
</script>