<template>
<div>
  <p class="text-uppercase fw-medium text-muted text-truncate mb-0">{{ title }}</p>
  <ul class="nav nav-tabs mb-3 mt-4" role="tablist">
    <li v-for="(token, index) in tokens" :key="`nav-panel-${index}`" class="nav-item" role="presentation" @click="selectTab(token.shipping)">
      <a class="nav-link" data-bs-toggle="tab" :href="`#nav-panel-${index}`" role="tab" aria-selected="false" tabindex="-1">
        Shipping {{token && token.shipping }}
        <span v-if="token.active" class="badge bg-info">Active</span>
        <span v-else-if="!token.active" class="badge bg-warning">Disabled</span>
      </a>
    </li>
    <li class="nav-item" role="presentation" @click="addToken">
      <a class="nav-link" data-bs-toggle="tab" href="#" role="tab" aria-selected="false" tabindex="-1">
        <span class="badge bg-success">+</span>
      </a>
    </li>
  </ul>
  <div class="tab-content text-muted">
    <div v-for="(token, index) in tokens"
      :key="`nav-panel-${index}`"
      :id="`nav-panel-${index}`"
      class="tab-pane"
      role="tabpanel"
    >
      <div class="row d-flex">
        <div class="col-xxl-3 col-md-6">
          <div>
            <label for="mint-price" class="form-label">DeadLine</label>
            <input v-model="token.deadline" type="date" class="form-control" id="mint-price">
          </div>
        </div>
        <div class="col-xxl-3 col-md-6">
          <div>
            <label for="mint-max" class="form-label">Next Shipment</label>
            <input v-model="token.next_shipping" type="date" class="form-control" id="mint-max">
          </div>
        </div>
 
        <div class="col-xxl-3 col-md-6">
          <div class="p-3">
            <div class="form-check form-switch form-switch-secondary form-switch-md" dir="ltr">
              <input v-model="token.active" type="checkbox" class="form-check-input" id="enabled">
              <label class="form-check-label" for="enabled">Shipment is enabled</label>
            </div>
          </div>
        </div>
      </div>
      <div class="row flex-d justify-content-end">
        <div class="col-sm-4 align-content-right">
          <button class="btn btn-success" @click="updateData(token.shipping)">Save</button>
          <button class="btn btn-success" @click="removeData(token.shipping)">Remove</button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  components: {},
  emits: ['tokenCreated', 'tokenUpdated'],
  props: {
    title: {
      type: String,
      default: 'Tokenomics',
    },
    tokens: {
      type: Array,
      default: function() { return [] },
    },
    addToken: {
      type: Function
    },
    onSelect: {
      type: Function
    },
    update: {
      type: Function
    },
    remove: {
      type: Function
    }
  },
  methods: {
    selectTab(shippingId) {
      this.onSelect(shippingId);
    },
    removeData(shippingId) {
      this.remove(shippingId);
    },
    updateData(shippingId) {
      this.update(shippingId);
     }
  }
}
</script>
